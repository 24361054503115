.container {
  margin-left: -15px;
  margin-right: -15px;

  @media screen and (min-width: 576px) {
    margin-left: 0;
    margin-right: 0;
  }
}

.holidays {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  margin-bottom: 30px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding-top: 15px;
  border-radius: 5px;
  overflow: hidden;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sort {
  label {
    display: block;
    line-height: 1.2em;
    letter-spacing: 0.05em;
    font-weight: 100;
    font-family: var(--header-font);
    text-transform: uppercase;
    color: var(--color-red);
  }
}

.sortButtons {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  gap: 15px;
}

.filterAndSort {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  gap: 30px;

  @media screen and (min-width: 992px) {
    flex-direction: row;
  }
}

.filters {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 15px;

  @media screen and (max-width: 767px) {
    &>div {
      flex-grow: 1;
      width: 100%;

      &>select {
        width: 100%;
      }
    }
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.cruiseNationLogo {
  position: relative;
  min-width: 28px;
  width: 28px;
  min-height: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
}

.header {
  display: flex;
  padding: 10px;
  gap: 10px;
  align-items: center;
  position: relative;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.9);
}

.itemHeader {
  flex-grow: 1;

  &>div>h3 {
    font-size: 16px;
    color: var(--color-maroon);
    letter-spacing: 0.05em;
    margin-bottom: 5px;
  }

  .detail {
    display: flex;
    gap: 10px;
    font-size: 12px;
    color: var(--color-grey-dark);

    @media screen and (min-width: 768px) {
      gap: 15px;
    }

    &>span {
      display: flex;
      align-items: center;
      gap: 5px;

      &>svg {
        display: none;
        font-size: 1.2em;

        @media screen and (min-width: 400px) {
          display: block;
        }
      }
    }
  }
}

.clickableItem {
  color: var(--color-red);
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.body {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  z-index: 1;
}

.footer {
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-height: 68px;
}

.includeds {
  flex-grow: 1;

  &>p {
    font-size: 16px;
    padding-left: 15px;
    color: white;
    font-family: var(--font-header);
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 600;
    margin-bottom: 2px;
  }
}

.included {
  display: flex;
  flex-wrap: wrap;
  gap: 2px 10px;
  padding: 0 0 15px 15px;

  &>div {
    font-size: 13px;
    color: white;
    gap: 5px;
  }
}

.darkGradientOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(0deg, #000 15%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  opacity: 0.65;
  mix-blend-mode: hard-light;
}

.viewButton {
  display: flex;
  align-items: center;
  height: 48px;
  border-radius: 0 !important;
  justify-content: center !important;
  width: 100%;
}


/* Loading Shimmer Effect*/

.grid-container {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  margin-bottom: 30px;
}

.grid-item {
  background-color: #f0f0f0;
  height: 280px;
  width: 100%;
}

.shimmer {
  min-height: 360px;
  background-image: linear-gradient(to right,
      #f0f0f0 0%,
      #e0e0e0 50%,
      #f0f0f0 100%);
  background-size: 400% 400%;
  animation: shimmer 2s ease infinite;
  border-radius: 5px;
}

@keyframes shimmer {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}