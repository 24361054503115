.horizontalScroll {
    gap: 15px;
}

.highlight {
    width: 360px;
    height: 580px;
    max-width: 100vw;
    position: relative;

    @media screen and (min-width: 768px) {
        width: 460px;
    }
}

.imageBackground {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transition: background-color 300ms ease-in-out;
    background: rgb(0, 0, 1);
    background: linear-gradient(0deg, rgba(0, 0, 1, 0) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0) 100%);
}

.title {
    position: absolute;
    top: 50%;
    width: 252px;
    font-family: var(--header-font);
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 36px;
    text-transform: uppercase;
    text-align: center;
    align-items: center;
    justify-content: center;
    transition: top 300ms ease-in-out, opacity 100ms ease-in-out;
    letter-spacing: 4px;
    color: white;
}

.path {
    width: 20px;
    height: 20px;
    position: absolute;
    background: rgba(250, 29, 29, 0.877);
    border-radius: 40px;
    top: 48%;
    right: 4%;
    visibility: hidden;
    animation: swipe-dot 2s 0.5s infinite;
}

.scrollRight {
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    top: 50%;
    right: 5%;
    color: white;
    font-size: 50px;

    transform-origin: 52% 62%;
    animation: swipe-hand 2s infinite;

    font-size: 50px;

    @media screen and (max-width: 768px) {
        font-size: 50px;
    }
}


@keyframes swipe-hand {
    25% {
        transform: translate(20px) rotate(30deg);
    }

    50% {
        transform: translate(-20px) rotate(-15deg);
    }

    100% {
        transform: translate(0px) rotate(0);
    }
}

@keyframes swipe-dot {
    12% {
        visibility: visible;
        opacity: 1;
        width: 40px;
    }

    25% {
        visibility: visible;
        transform: translate(-65px);
        width: 20px;
    }

    26% {
        opacity: 0;
        visibility: hidden;
    }
}

.content {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 48%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    opacity: 0;
    transition: top 300ms ease-in-out, opacity 100ms ease-in-out;
    height: fit-content;
}

.contentTitle {
    font-family: var(--header-font);
    font-size: 36px;
    width: 252px;
    text-transform: uppercase;
    text-align: center;
    align-items: center;
    justify-content: center;
    letter-spacing: 4px;
    color: white;
}

.highlight:hover .title {
    opacity: 0;
    top: 45%;
}

.button {
    position: absolute;
    width: 200px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: bottom 300ms ease-in-out, opacity 100ms ease-in-out;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 20px;
    color: white;
    border-color: white;
    font-weight: 400;
    letter-spacing: 0.1em;
}

.highlight:hover .imageBackground {
    background-color: rgba(0, 0, 0, 0.5);
}

.highlight:hover .content {
    top: 45%;
    opacity: 1;
    padding-bottom: 60px;
}

.highlight:hover .button {
    opacity: 1;
    bottom: 0;
}

.button:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.previewDescription {
    display: flex;
    justify-content: center;
}

.previewDescription>p:first-of-type {
    font-size: 12.8px;
    width: 80%;
    font-weight: 500;
    color: white;
    padding-top: 0;
    margin-top: 2px;
    font-family: var(--body-font);
    display: -webkit-box;
    text-align: center;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.previewDescription>p {
    display: none;
}

/* Modal styles */
.alertDialogContent {
    width: 1280px;
    height: 720px;
    max-height: 100%;
    cursor: initial;
    max-width: 100%;
}

.alertDialogMain {
    width: 100%;
    height: 100%;
    position: relative;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(90deg, #00000087 0%, #0000006E 40%, #00000000 60%, #00000000 100%) 0% 0% no-repeat padding-box;
    opacity: 1;

    @media screen and (max-width: 1028px) {
        background: transparent linear-gradient(90deg, #00000087 0%, #0000006E 52%, #00000000 80%, #00000000 100%) 0% 0% no-repeat padding-box;
    }

    @media screen and (max-width: 628px) {
        background: rgba(0, 0, 0, 0.4)
    }
}

.higlightBackgroundOverlay {
    border-radius: 0;
}

.alertDialogInformation {
    position: absolute;
    width: 600px;
    max-width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 60px;
    overflow-y: auto;

    @media screen and (max-width: 620px) {
        padding: 60px 20px;
    }
}

.highlightImage {
    position: relative;
    width: 100%;
    height: 100%;
}

.highlightDialogTitle {
    font-size: 42px;
    font-weight: 500;
    font-family: var(--header-font);
    margin-bottom: 10px;
    color: white;
    text-transform: uppercase;

    @media screen and (max-width: 620px) {
        font-size: 35px;
    }
}

.alertSubTitle {
    font-size: 14px;
    font-weight: 500;
    font-family: var(--header-font);
    color: white;
    text-transform: uppercase;
}

.alertDescription {
    width: 399px;
    @media screen and (max-width: 620px) {
        width: 100%;
    }
}

.alertDescription>p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5rem;
    color: white;
    padding-top: 0;
    font-family: var(--body-font);
    text-shadow: 0px 3px 6px #000000BA;

    @media screen and (max-width: 620px) {
        font-size: 15px;
        line-height: 1.2rem;
    }
}

.alertDescription>p:first-of-type {
    margin-top: 30px;
}

.alertDialogClose {
    position: absolute;
    top: 60px;
    color: white;
    right: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: 620px) {
        right: 20px;
        width: 40px;
        height: 40px;
    }
}


.alertDialogBtn {
    position: absolute;
    bottom: 50px;
    min-width: 200px;
    color: white;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    right: 60px;
    cursor: pointer;

    @media screen and (max-width: 620px) {
        right: 50%;
        min-width: 80%;
        font-size: 15px;
        transform: translateX(50%);
    }
}

.introduction{
    margin-bottom: 50px;
}

.alertDialogCloseIcon {
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));
}

.alertDialogClose:hover {
    background-color: transparent;
}