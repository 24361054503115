.modal {
  position: fixed;
  padding: 0;
  border-radius: 5px;
  border: none;
  background: white;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  opacity: 0;
  transform-origin: center;
  width: 90%;
  max-width: 600px;
  height: 90vh;
  max-height: 800px;
}

.modal[open] {
  opacity: 1;
  animation: slideToCenter 0.3s ease-out;
}

.modal.closing {
  animation: slideFromCenter 0.3s ease-in;
}

.modal::backdrop {
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  animation: fadeIn 0.3s ease-out forwards;
}

.modal.closing::backdrop {
  animation: fadeOut 0.3s ease-in forwards;
}

/* Animations */
@keyframes slideToCenter {
  from {
    transform: translate(var(--start-x), var(--start-y)) scale(0);
    opacity: 0;
  }
  to {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
}

@keyframes slideFromCenter {
  from {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
  to {
    transform: translate(var(--start-x), var(--start-y)) scale(0);
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.modalContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid var(--color-grey-light, #e0e0e0);
}

.modalHeader h4 {
  margin: 0;
  padding-right: 0;
  width: auto;
  max-width: 100%;
  color: var(--color-maroon);
}

.modalContent {
  flex: 1;
  overflow-y: auto;
  padding: 15px;
}

.shipImage {
  width: 100%;
  height: 300px;
  margin-bottom: 20px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.shipImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.shipDetails {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.shipInfo h5,
.shipSpecs h5 {
  color: var(--color-maroon);
  margin-bottom: 10px;
}

.shipInfo p {
  font-size: 16px;
  line-height: 1.5;
  color: var(--color-grey-dark);
}

.specsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
}

.specItem {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.specLabel {
  font-size: 14px;
  color: var(--color-grey-dark);
}

.specValue {
  font-size: 16px;
  color: var(--color-grey-darker);
  font-weight: 500;
}

.modalFooter {
  padding: 15px;
  border-top: 1px solid var(--color-grey-light, #e0e0e0);
  display: flex;
  justify-content: flex-end;
}

.closeButton {
  cursor: pointer;
  font-size: 28px;
  padding: 15px;
  background: none;
  border: none;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -15px -15px -15px 0;
}

.viewButton {
  width: 100%;
} 