.select {
  border-radius: 4px;
  border: 1px solid var(--color-grey-medium);
  padding: 0.75em 0.75em;
  padding-right: 2.5em;
  font-family: var(--body-font);
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: var(--color-grey-deep);
  text-transform: camelcase;

  &::-ms-expand {
    display: none;
  }
}

.mainWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;
}

.labelStyle {
  display: block;
  line-height: 1.2em;
  letter-spacing: 0.05em;
  font-weight: 100;

  font-family: var(--header-font);
  text-transform: uppercase;
  color: var(--color-red);

  svg {
    margin-right: 10px;
    margin-top: -0.05em;
  }
}

.wrapper {
  position: relative;

  &:after {
    border: 2.5px solid transparent;
    border-radius: 2px;
    border-right: 0;
    border-top: 0;
    content: " ";
    display: block;
    height: 0.625em;
    margin-top: -0.4375em;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: rotate(-45deg);
    transform-origin: center;
    width: 0.625em;
    right: 1.125em;
    z-index: 4;

    border-color: var(--color-grey-deep);
  }
}