.modal {
  position: fixed;
  padding: 0;
  border-radius: 5px;
  border: none;
  background: white;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  opacity: 0;
  transform-origin: center;
  width: 90%;
  max-width: 520px;
  height: 80vh;
  max-height: 800px;
}

.modal[open] {
  opacity: 1;
  animation: slideToCenter 0.3s ease-out;
}

.modal.closing {
  animation: slideFromCenter 0.3s ease-in;
}

.modal::backdrop {
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  animation: fadeIn 0.3s ease-out forwards;
}

.modal.closing::backdrop {
  animation: fadeOut 0.3s ease-in forwards;
}

/* Animations */
@keyframes slideToCenter {
  from {
    transform: translate(var(--start-x), var(--start-y)) scale(0);
    opacity: 0;
  }
  to {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
}

@keyframes slideFromCenter {
  from {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
  to {
    transform: translate(var(--start-x), var(--start-y)) scale(0);
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Flex container inside the dialog */
.modalContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

/* Header, content, footer structure */
.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid var(--color-grey-light, #e0e0e0);
}

.modalHeader h4 {
  margin: 0;
  padding-right: 0;
  width: auto;
  max-width: 100%;
  color: var(--color-maroon);
}

.modalContent {
  flex: 1;
  overflow-y: auto;
  padding: 15px;

  &>h5 {
    color: var(--color-maroon);
  }
}

.modalContent p {
  font-size: 16px;
  width: 500px;
  max-width: 100%;
}

.modalFooter {
  padding: 15px;
  border-top: 1px solid var(--color-grey-light, #e0e0e0);
  display: flex;
  justify-content: flex-end;
}

/* Close button */
.closeButton {
  cursor: pointer;
  font-size: 28px;
  padding: 15px;
  background: none;
  border: none;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -15px -15px -15px 0; /* Offset the button padding to align properly */
}

/* Table styles - keep your original table styling */
.table {
  width: 100%;
  border-collapse: collapse;
}

.table thead tr th, 
.table tbody tr td {
  text-align: left;
  border-bottom: 1px solid var(--color-grey-light, #e0e0e0);
  padding: 10px;
  font-size: 14px;
}

.table thead tr th:first-child, 
.table tbody tr td:first-child {
  padding-left: 0;
}

.table thead tr th:last-child, 
.table tbody tr td:last-child {
  padding-right: 0;
}

.table thead tr th {
  border-bottom: 1px solid var(--color-grey, #9e9e9e);
  padding-bottom: 2px;
}

/* Day styling */
.dayNumber {
  position: relative;
}

.portIcon {
  width: 1em;
  height: 1em;
  margin-right: 10px;
  color: var(--color-red, #e53e3e);
}

.sailingDay {
  color: var(--color-grey-dark, #616161);
}

.arriveDepart {
  font-size: 0.8em;
  line-height: 1em;

  &>svg {
    margin-right: 5px;
  }
}

.viewButton {
  width: 100%;
}