.horizontalScroll {
  gap: 15px;
}

.thingsToDoItem {
  width: 360px;
  height: 580px;
  max-width: 100vw;
  position: relative;

  @media screen and (min-width: 768px) {
      width: 460px;
  }
}

.thingsToDoItemShort {
  height: 490px;
}

.imageBackground{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transition: background-color 300ms ease-in-out;
  background: rgb(0,0,1);
  background: linear-gradient(0deg, rgba(0,0,1,0) 0%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,0) 100%);
}

.title{
  position: absolute;
  top: 50%;
  width: 252px;
  font-family: var(--header-font);
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 36px;
  text-transform: uppercase;
  text-align: center;
  align-items: center;
  justify-content: center;
  transition: top 300ms ease-in-out, opacity 100ms ease-in-out;
  letter-spacing: 4px;
  color: white;
}


.path {
  width: 20px;
  height: 20px;
  position: absolute;
  background: rgba(250, 29, 29, 0.877);
  border-radius: 40px;
  top: 48%;
  right: 4%;
  visibility: hidden;
  animation: swipe-dot 2s 0.5s infinite;
}

.scrollRight {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  top: 50%;
  right: 5%;
  color: white;
  font-size: 50px;

  transform-origin: 52% 62%;
  animation: swipe-hand 2s infinite;

  font-size: 50px;

  @media screen and (max-width: 768px) {
      font-size: 50px;
  }
}


@keyframes swipe-hand {
  25% { transform: translate(20px) rotate(30deg); }
  50% { transform: translate(-20px) rotate(-15deg); }
  100% { transform: translate(0px) rotate(0); }
}

@keyframes swipe-dot {
12% {
  visibility: visible;
  opacity: 1;
  width: 40px;
}
25% {
  visibility: visible;
  transform: translate(-65px);
  width: 20px;
}
26% { 
  opacity: 0;
  visibility: hidden;
}
}

.content{
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 48%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  opacity: 0;
  transition: top 300ms ease-in-out, opacity 100ms ease-in-out;
  height: fit-content;
}

.contentTitle{
  font-family: var(--header-font);
  font-size: 36px;
  width: 252px;
  text-transform: uppercase;
  text-align: center;
  align-items: center;
  justify-content: center;
  letter-spacing: 4px;
  color: white;
}

.thingsToDoItem:hover .title{
  opacity: 0;
  top: 45%;
}

.button{
  position: absolute;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: bottom 300ms ease-in-out, opacity 100ms ease-in-out;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 20px;
}

.thingsToDoItem:hover .imageBackground{
  background-color: rgba(0,0,0,0.5);
}

.thingsToDoItem:hover .content{
  top: 45%;
  opacity: 1;
}

.thingsToDoItem:hover .button{
  opacity: 1;
  bottom: 50px;
}

.description{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.descriptionItem{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.descriptionItem>p{
  font-size: 13px;
  width: 80%;
  font-weight: 500;
  color: white;
  padding-top: 0;
  margin-top: 2px;
  font-family: var(--body-font);
  display: -webkit-box;
  text-align: center;
  -webkit-line-clamp: 12;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.descriptionOne>p:first-of-type {
  font-size: 12.8px;
  width: 80%;
  font-weight: 500;
  color: white;
  padding-top: 0;
  margin-top: 2px;
  font-family: var(--body-font);
  display: -webkit-box;
  text-align: center;
  -webkit-line-clamp: 12;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.descriptionOneShort>p:first-of-type{
  -webkit-line-clamp: 5;
  font-size: 12.8px;
  width: 80%;
  font-weight: 500;
  color: white;
  padding-top: 0;
  margin-top: 2px;
  font-family: var(--body-font);
  display: -webkit-box;
  text-align: center;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.descriptionTwo>p:nth-child(-n+2) {
  font-size: 12.8px;
  width: 80%;
  font-weight: 500;
  color: white;
  padding-top: 0;
  margin-top: 2px;
  font-family: var(--body-font);
  display: -webkit-box;
  text-align: center;
  -webkit-line-clamp: 12;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.descriptionTwoShort>p:nth-child(-n+2){
  -webkit-line-clamp: 5;
  font-size: 12.8px;
  width: 80%;
  font-weight: 500;
  color: white;
  padding-top: 0;
  margin-top: 2px;
  font-family: var(--body-font);
  display: -webkit-box;
  text-align: center;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.descriptionThree>p:nth-child(-n+3) {
  font-size: 12.8px;
  width: 80%;
  font-weight: 500;
  color: white;
  padding-top: 0;
  margin-top: 2px;
  font-family: var(--body-font);
  display: -webkit-box;
  text-align: center;
  -webkit-line-clamp: 12;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.descriptionThreeShort>p:nth-child(-n+3){
  -webkit-line-clamp: 5;
  font-size: 12.8px;
  width: 80%;
  font-weight: 500;
  color: white;
  padding-top: 0;
  margin-top: 2px;
  font-family: var(--body-font);
  display: -webkit-box;
  text-align: center;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.description>p {
  display: none;
}